<template>
  <div class="container-fluid px-4 py-5">
    <div class="row">
      <div v-if="controls" class="col-md-3">

      </div>
      <div class='d-flex flex-row'>
        <force-graph-controller v-if="showgraphSetting" class=" p-2 flex-shrink-1" />
        <Thoughtversealt />
      </div>
      <div v-if="showMeta" class="col-md-3 metaview">
        <MetaView />
      </div>
    </div>
  </div>
</template>

<script>
import Thoughtversealt from '@/common/widgets/ThoughtVerseAltWidget/ThoughtVerseAltWidget.vue';
import Thoughtversecontrols from '@/common/widgets/ThoughtVerseControlsWidget/ThoughtVerseControlsWidget.vue';
import MetaView from '@/common/components/MetaView.vue';

export default {
  name: 'VR',
  metaInfo: {
    title: 'VR',
  },
  components: {
    Thoughtversealt,
    // eslint-disable-next-line vue/no-unused-components
    Thoughtversecontrols,
    MetaView,
  },
  data() {
    return {
      showgraphSetting: true,
    };
  },
  computed: {
    showMeta() {
      if (this.$store.state.wikiInformation) {
        const wikiInfoLength = this.$store.state.wikiInformation.search.length;
        if (wikiInfoLength > 0) {
          return true;
        }
      }
      if (this.$store.state.selectedNode && this.$store.state.selectedNode.nodeLabel) {
        return true;
      }
      if (this.$store.state.wolframInformation && this.$store.state.wolframInformation.answer) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.metaview {
  z-index: 10;
}
</style>
