<template>
  <div class="container">
    <div class="card nodes-card">
      <div class="card-body">
        <h4 class="card-title">{{ $t('comp_forceGraphController--nodes') }}</h4>
        <h6 class="card-subtitle mb-2 text-muted">{{ $t('comp_forceGraphController--nodesFilter') }}</h6>
          <div v-for="(value, key, index) in nodeFilterList" :key="key" type="button"
            class="btn" :class="value.isSelected !== 0 ? 'btn-success' : 'btn-outline-secondary'"
            v-on:click="nodeTypeClicked($event, key, value.isSelected, index)"
            >
              {{key}} ({{value.count}})

              <i class="bx bx-low-vision"></i>
            </div>
      </div>
    </div>
    <div class="card edges-card">
      <div class="card-body">
        <h4 class="card-title">{{ $t('comp_forceGraphController--relationships') }}</h4>
        <h6 class="card-subtitle mb-2 text-muted">{{ $t('comp_forceGraphController--relationshipsFilter') }}</h6>
          <button v-for="(value, key, index) in edgeFilterList" :key="key" type="button"
          class="btn" :class="value.isSelected !== 0 ? 'btn-success' : 'btn-outline-secondary'"
          v-on:click="edgeTypeClicked($event, key, value.isSelected, index)">
            {{key}} ({{value.count}})
          </button>
      </div>
    </div>

  <div class="card-deck nodeControls">
    <div class="card" v-for="(value, key, index) in selectedNodes" :key="key" >
      <div class="card-body" v-if="value.isSelected">
        <h4 class="card-title">{{key}} ({{value.count}}) </h4>

        Size: <input type="range" :value="value.nodeSize" class="form-control-range" min="0" max="200" step="1" @change="nodeTypeRangeUpdated($event, key, value, index)">
      </div>
    </div>
  </div>

  <div class="card-deck edgeControls">
    <div class="card" v-for="(value, key, index) in selectedEdges" :key="key" >
      <div class="card-body" v-if="value.isSelected">
        <h4 class="card-title">{{key}} ({{value.count}}) </h4>

        Size: <input type="range" :value="value.edgeWidth" class="form-control-range" min="0" max="20" step="1" @change="edgeTypeRangeUpdated($event, key, value, index)">
      </div>
    </div>
  </div>

  </div>
</template>

<script>

import { mapActions } from 'vuex';

export default {
  name: 'force-graph-controller',
  data() {
    return {
      nodeFilterList: [],
      edgeFilterList: [],
    };
  },

  mounted() {
    this.loadSettingsFromStore();
  },

  methods: {
    ...mapActions({
    }),
    nodeTypeClicked(event, key, value) {
      if (value > 0) {
        this.nodeFilterList[key].isSelected = 0;
      } else {
        this.nodeFilterList[key].isSelected = 1;
      }
      this.$store.dispatch('updateGraphSetting', {
        nodeFilter: this.nodeFilterList,
        edgeFilter: this.edgeFilterList,
      });
    },
    edgeTypeClicked(event, key, value) {
      if (value > 0) {
        this.edgeFilterList[key].isSelected = 0;
      } else {
        this.edgeFilterList[key].isSelected = 1;
      }
      this.$store.dispatch('updateGraphSetting', {
        nodeFilter: this.nodeFilterList,
        edgeFilter: this.edgeFilterList,
      });
    },
    nodeTypeRangeUpdated(event, key) {
      this.nodeFilterList[key].nodeSize = event.target.value;
      this.$store.dispatch('updateGraphSetting', {
        nodeFilter: this.nodeFilterList,
        edgeFilter: this.edgeFilterList,
      });
    },
    edgeTypeRangeUpdated(event, key) {
      this.edgeFilterList[key].edgeWidth = event.target.value;
      this.$store.dispatch('updateGraphSetting', {
        nodeFilter: this.nodeFilterList,
        edgeFilter: this.edgeFilterList,
      });
    },
    loadSettingsFromStore() {
      const graphFromState = this.$store.state.graph;
      const filteredNodeTypes = graphFromState.nodes.map((x) => x.labels).flat();
      const filteredEdgeTypes = graphFromState.links.map((x) => x.label);

      // eslint-disable-next-line
        const nodeTypeWithCount = filteredNodeTypes.reduce((acc, curr) => ((acc[curr] = (acc[curr] || 0) + 1, acc)), {});
      // eslint-disable-next-line
        const edgeTypeWithCount = filteredEdgeTypes.reduce((acc, curr) => ((acc[curr] = (acc[curr] || 0) + 1, acc)), {});
      // const nodeType = [...new Set(filteredNodeTypes)];
      // const edgeType = [...new Set(filteredEdgeTypes)];

      this.nodeFilterList = Object.fromEntries(
        Object.keys(nodeTypeWithCount).map((x) => [x, { count: nodeTypeWithCount[x], isSelected: 1, nodeSize: 5 }]),
      );
      this.edgeFilterList = Object.fromEntries(
        Object.keys(edgeTypeWithCount).map((x) => [x, { count: edgeTypeWithCount[x], isSelected: 1, edgeWidth: 0 }]),
      );
      this.nodeFilterList = Object.assign(this.nodeFilterList, graphFromState.setting.nodeFilter);
    },

  },

  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'setGraph') {
        this.loadSettingsFromStore();
      }
    });
  },

  computed: {
    selectedNodes() {
      return Object.fromEntries(
        Object.entries(this.nodeFilterList).filter(([, value]) => value.isSelected),
      );
    },
    selectedEdges() {
      return Object.fromEntries(
        Object.entries(this.edgeFilterList).filter(([, value]) => value.isSelected),
      );
    },
  },

  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss" scoped>

</style>
