<template>
  <div id="profile_comp">
    <!-- https://upload.wikimedia.org/wikipedia/commons/5/56/Donald_Trump_official_portrait.jpg -->
    <div class="header">
      <h3>{{ profile.name || profile.gitlabId }}</h3>
      <h4 v-bind:style="{ color: profile.color }">{{ profile.nodeLabel }}</h4>
      <img src="{../assets/profile/bookmark.svg}" alt="" />
    </div>

    <div class="content">
      <div class="row d-flex">
        <div class="col-md-6 img-wrap">
          <img class="img_thumb" width="100%" :src="profile.avatarUrl" alt="" />
        </div>
        <div class="col-md-6 profile_detail info-wrap">
          <!-- <p v-for="(props) in Object.keys(profile)" :key="props">
                      <strong>{{props}}</strong> {{profile[props]}}
                    </p> -->

          <p><strong>Username:</strong> {{ profile.username }}</p>
          <p><strong>GitLab:</strong> {{ profile.gitlabId }}</p>
          <p>
            <strong>Location</strong> {{ profile.location || "undisclosed" }}
          </p>
          <p><strong>Email:</strong> {{ profile.email }}</p>
          <p><strong>State:</strong> {{ profile.state }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    profile() {
      let profile = {};
      if (
        this.$store.state.selectedNode
        && this.$store.state.selectedNode.nodeLabel
      ) {
        profile = this.$store.state.selectedNode;
      }
      return profile;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;700&display=swap");
#profile_comp {
  // max-width: 500px;
  // min-width: 400px;
  padding: 20px 0 20px 10px;
  // min-height: 570px;
  border-radius: 4px;
  box-shadow: 3px 3px 12px 2px rgba(119, 119, 119, 0.596);
  background: linear-gradient(
    to bottom,
    #d0d0ce 0%,
    #d3d3d1 18%,
    #dddad5 41%,
    #e3dfde 58%,
    #e5e1e0 82%,
    #e5e1e0 100%
  );
  .img_thumb {
    border-radius: 8px;
    box-shadow: -3px 5px 12px 2px rgba(0, 0, 0, 0.5);
  }
  .header {
    border-bottom: 2px solid rgb(204, 204, 204);
    padding-bottom: 10px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;

    h3 {
      font-family: "Poppins", sans-serif;
      font-weight: 700;
      // font-size: 1.25rem;
      text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
        1px 1px 0 #fff;
    }
    img {
      width: 25px;
    }
  }
  .profile_detail {
    font-family: "Poppins", sans-serif;
    line-height: 3px;
    font-size: 0.8125rem;
    padding-top: 20px;
    padding-right: 10px;
  }
  .description {
    position: absolute;
    bottom: 0;
    background-color: #f6f6f6;
    border-radius: 8px;
    font-size: 0.8125rem;
    padding: 150px 10px 50px 10px;
    margin-right: 10px;
  }
  .img-wrap{
      width: 30%!important;
  }
  .info-wrap{
      width: 70%!important;
  }
}
</style>
