<template>
  <li class="list-group-item metainfo__list-item pt-3">
    <div class="d-flex flex-column metainfo__result">
      <a
        target="_blank"
        class="
          metainfo__result-title
          d-flex
          align-items-center
          justify-content-between
          py-2
        "
      >
        {{ wf.question }}
      </a>
      <div class="metainfo__result-content d-flex flex-column">
        <div class="metainfo__result-detail">{{ wf.answer }}</div>
      </div>
    </div>
    <hr />
    <div class="d-flex align-items-center flex-row metainfo__source pb-1">
      <div class="metainfo__source-tag">{{ $t('global_poweredBy') }}</div>
      <div class="metainfo__source-keyword d-flex align-items-center">
        <img
          src="@/assets/images/Wolfram_Alpha-Logo.png"
          :alt="metaSource"
          class="metainfo__source-img"
        />
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: 'wolfram-comp',

  created() {
    this.$highway.singleDispatcher('askWolfram');
  },
  computed: {
    wf() {
      return {
        question: this.$store.state.wolframInformation.question,
        answer: this.$store.state.wolframInformation.answer,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
