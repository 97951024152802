<template>
  <div>
    <MetaToggle />
    <MetaContent />
  </div>
</template>

<script>
import MetaToggle from '@/common/components/MetaToggle.vue';
import MetaContent from '@/common/components/MetaContent.vue';

export default {
  name: 'MetaView',

  components: {
    MetaToggle,
    MetaContent,
  },
};
</script>

<style lang="scss" scoped>
hr {
  margin: 0.5rem 0;
  color: gray!important;
}
</style>
