<template>
  <div class="d-flex flex-column px-2">
    <div class="d-flex flex-between align-items-center">
      <div class="h6 flex-fill">Meta Selection</div>
      <div class="d-flex btn">
        <i
          class="bx bx-arrow-from-left"
          alt="Clear selection"
          @click="clearMetaSelection()"
        />
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'MetaToggle',

  methods: {
    clearMetaSelection() {
      this.$store.dispatch('clearMetaSelection');
    },
  },
};
</script>

<style lang="scss" scoped>
hr {
  margin: 0.5rem 0;
}
</style>
