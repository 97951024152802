<template>
  <div class="row metainfo">
    <div class="metainfo__title mx-2 mt-2">
      {{ $t("comp_metaContent--searchTitle") }}:
      <span class="metainfo__title-span">{{ wikiWord }}</span>
    </div>
    <ThreeDModelViewer v-if="show3DViewer" />

    <ul class="list-group p-3 metainfo__list">
      <WolframComp v-if="showWolframWidget" />
      <WikiDataCard v-if="showWikiCard" />
      <UserProfileComp v-if="showProfileCard" />
    </ul>
  </div>
</template>

<script>
import WikiDataCard from '@/common/widgets/WikiDataCardWidget/WikiDataCardWidget.vue';
import UserProfileComp from '@/common/components/UserProfileComp.vue';
import ThreeDModelViewer from '@/common/widgets/ThreeDModelWidget/ThreeDModelWidget.vue';
import WolframComp from '@/common/widgets/WolFramWidget/WolFramWidget.vue';

export default {
  name: 'MetaContent',

  components: {
    WikiDataCard,
    WolframComp,
    UserProfileComp,
    ThreeDModelViewer,
  },

  computed: {
    wikiWord() {
      return this.$store.state.wikiWord;
    },

    showProfileCard() {
      if (
        this.$store.state.selectedNode
        && this.$store.state.selectedNode.nodeLabel === 'User'
      ) {
        return true;
      }
      return false;
    },

    showWolframWidget() {
      if (
        this.$store.state.wolframInformation
        && this.$store.state.wolframInformation.answer
        && this.$store.state.settings.widget
        && this.$store.state.settings.widget.showWolfram
      ) {
        return true;
      }
      return false;
    },

    showWikiCard() {
      if (
        this.$store.state.wikiInformation
        && this.$store.state.wikiInformation.length > 0
        && this.$store.state.settings.widget
        && this.$store.state.settings.widget.showWikidata
      ) {
        return true;
      }
      return false;
    },

    show3DViewer() {
      if (
        this.$store.state.settings.widget
        && this.$store.state.settings.widget.show3DViewer
        && this.$store.state.threeDModelData
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
ThreeDModelViewer {
  min-height: 400px;
}
</style>
